<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column
                prop="rechargeNo"
                label="编号"
                show-overflow-tooltip
                width="100"
            >
                <template slot-scope="scope">
                    <span
                        style="color: #2370eb; cursor: pointer"
                        @click="toDetail(scope.row)"
                        >{{ scope.row.rechargeNo }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="下单时间"
                show-overflow-tooltip
                width="160"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="账户" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.launchBO.account }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.rechargeAmount }}元</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-tabl e-column label="充值状态" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ $tableDataHandle.rechargeStatus(scope.row.status) }}
                </template>
            </el-tabl>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" fixed="right" width="100">
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="btnP.revoke && scope.row.status == 1"
                        @click="toRevoke(scope.row)"
                    >
                        撤销
                    </el-button>
                    <span
                        class="line"
                        v-if="
                            btnP.revoke && btnP.audit && scope.row.status == 1
                        "
                        >|</span
                    >
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="btnP.audit && scope.row.status == 1"
                        @click="toAudit(scope.row)"
                    >
                        审核
                    </el-button>
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="btnP.pay && scope.row.status == 2"
                        @click="toPay(scope.row)"
                    >
                        打款
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { rechargeList } from '@/api/user/launch/pay.js';
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
        };
    },
    methods: {
        // 获取列表
        getData(searchStatus, pageNum, pageSize, btnP) {
            // console.log(searchStatus);
            this.btnP = btnP;
            let data = {
                param: {},
                pageNum: pageNum,
                pageSize: pageSize,
            };
            if (searchStatus) {
                let statusList = [];
                switch (searchStatus) {
                    case 'auditing':
                        statusList.push(1);
                        break;
                    case 'toPay':
                        statusList.push(2);
                        break;
                    case 'rejected':
                        statusList.push(3);
                        break;
                    case 'Paid':
                        statusList.push(4);
                        break;
                    case 'revoked':
                        statusList.push(5);
                        break;
                }
                data.param.statusList = statusList;
            } else {
                if (btnP.children.length == 5) {
                    data.param.statusList = [1, 2, 3, 4, 5];
                } else {
                    let statusList = [];
                    btnP.children.forEach((item) => {
                        switch (item) {
                            case 'auditing':
                                return statusList.push(1);
                            case 'toPay':
                                return statusList.push(2);
                            case 'rejected':
                                return statusList.push(3);
                            case 'Paid':
                                return statusList.push(4);
                            case 'revoked':
                                return statusList.push(5);
                        }
                    });
                    data.param.statusList = statusList;
                }
            }

            rechargeList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('totalNum', res.data.total);
                }
            });
        },
        //     审核
        toAudit(data) {
            this.$emit('toAudit', data);
        },
        toRevoke(data) {
            this.$emit('toRevoke', data);
        },
        toPay(data) {
            this.$emit('toPay', data);
        },
        toDetail(data) {
            this.$emit('toDetail', data);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;

    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 40px;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        height: 40px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: none;
            position: relative;
        }
        button:nth-child(1) {
            line-height: 2;
        }
        button:nth-child(2) {
            /deep/ .el-icon-caret-bottom {
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
    .el-table {
        /deep/tr td:nth-child(1) .cell,
        /deep/tr td:nth-child(6) .cell {
            padding-right: 20px;
            position: relative;
            .mini_circle {
                content: '';
                position: absolute;
                top: 2px;
                right: 0;
                width: 20px;
                height: 16px;
                background: #ff9385;
                border-radius: 63px 63px 63px 1px;
                font-size: 12px;
                font-style: normal;
                text-align: center;
                line-height: 16px;
                color: #fff;
            }
        }
    }
}
.line {
    color: #e5e5e5;
    margin: 0 13px;
}
</style>
