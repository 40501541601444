<template>
    <div class="audit_box">
        <BaseInfo ref="baseInfo"></BaseInfo>
        <AuditDetail ref="auditDetail"></AuditDetail>
        <el-form label-position="left" label-width="80px" size="small">
            <el-form-item label="审核附件" required>
                <el-upload
                    class="upload-demo"
                    :action="action"
                    list-type="picture-card"
                    :on-progress="progress"
                    :limit="1"
                    :file-list="fileList"
                    ref="businessLicense"
                    :on-success="onBusinessLicense"
                    :before-upload="beforeAvatarUploads"
                >
                    <el-button
                        size="small"
                        type="primary"
                        style="background:#fff;padding:6px 20px;color:#2370EB;margin-top:5px"
                        >添加</el-button
                    >
                    <div
                        slot="file"
                        slot-scope="{ file }"
                        style="text-align:center"
                        v-if="form.attachmentUrl"
                    >
                        <img
                            class="el-upload-list__item-thumbnail"
                            :src="form.attachmentUrl"
                            alt=""
                            v-if="
                                form.attachmentUrl.indexOf('.jpg') != -1 ||
                                    form.attachmentUrl.indexOf('.png') != -1 ||
                                    form.attachmentUrl.indexOf('.gif') != -1
                            "
                        />
                        <span
                            v-if="
                                form.attachmentUrl.indexOf('.pdf') != -1 ||
                                    form.attachmentUrl.indexOf('.PDF') != -1
                            "
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span v-if="form.attachmentUrl.indexOf('.doc') != -1">
                            <img src="@/img/word.png" alt="" />
                        </span>
                        <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-delete"
                                @click="handleDownload(file)"
                            >
                                <i class="el-icon-download"></i>
                            </span>
                            <span
                                class="el-upload-list__item-delete"
                                @click="handleRemove(file, index)"
                            >
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </div>
                </el-upload>
            </el-form-item>
            <el-form-item label="审核备注">
                <el-input
                    v-model="form.remark"
                    type="textarea"
                    placeholder="审核备注"
                    rows="6"
                    style="width:270px"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="dialog_footer">
            <el-button round size="mini" @click="onClose">取 消</el-button>
            <el-button type="primary" round size="mini" @click="onSubmit"
                >审 核</el-button
            >
        </div>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
import { getOneRecharge, rechargeAudit } from '@/api/user/launch/pay.js';
import BaseInfo from '../detail/components/baseInfo.vue';
import AuditDetail from '../detail/components/auditDetail.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {},
            action: this.$url.url() + '/file/local/upload/contract',
            dialogVisible: false,
            dialogImageUrl: '',
            fileList: [],
            loading: false,
            tableRow: {},
        };
    },
    components: {
        BaseInfo,
        AuditDetail,
    },
    created() {},
    methods: {
        getData(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            getOneRecharge(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.tableRow = res.data;
                    setTimeout(() => {
                        this.$refs.baseInfo.getData(res.data);
                        this.$refs.auditDetail.getData(res.data);
                    }, 0);
                }
            });
        },
        progress() {
            this.loading = true;
        },
        // 预览图片
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 上传附件
        onBusinessLicense(response, index) {
            this.loading = false;
            if ((response.code = 200)) {
                this.$message.success('附件上传成功');
                this.form.attachmentUrl = response.data;
            } else {
                this.$message.success('附件上传失败');
            }
        },
        beforeAvatarUploads(file, index) {
            this.file = file;
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 20MB!');
            }
            return isLt2M;
        },
        // 下载
        handleDownload(file) {
            // console.log(file.url);
            if (
                file.url.indexOf('.doc') != -1 ||
                file.url.indexOf('.docx') != -1
            ) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' +
                        file.url
                );
            } else {
                window.open(file.url, '_blank');
            }
        },
        // 删除
        handleRemove(file, index) {
            this.$refs.businessLicense.uploadFiles = [];
            this.form.attachmentUrl = '';
        },
        onSubmit() {
            if (!this.form.attachmentUrl) {
                return this.$message.error('请上传审核附件');
            }
            let data = {
                param: {
                    rechargeId: this.tableRow.id,
                    attachmentUrl: this.form.attachmentUrl,
                    remark: this.form.remark,
                    type: 2,
                },
            };

            rechargeAudit(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.onClose();
                    this.form = {};
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.audit_box {
    width: 100%;
    height: 100%;
    padding: 24px 35px;
    box-sizing: border-box;
    .dialog_footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 45px;
        line-height: 45px;
        text-align: right;
        padding-right: 10px;
        box-sizing: border-box;
        border-top: 1px solid #e5e5e5;
    }
}
.el-form-item {
    margin-bottom: 10px;
}
/deep/ .el-upload-list__item:hover {
    background-color: #fff;
}
/deep/ .el-upload--picture-card {
    background-color: transparent;
    width: auto;
    height: auto;
    border: none;
    line-height: 1;
    display: block;
    text-align: left;
}
/deep/ .el-upload-list__item.is-success {
    width: 80px !important;
    height: 80px !important;
}
</style>
