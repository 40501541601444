var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"audit_box"},[_c('BaseInfo',{ref:"baseInfo"}),_c('AuditDetail',{ref:"auditDetail"}),_c('el-form',{attrs:{"label-position":"left","label-width":"80px","size":"small"}},[_c('el-form-item',{attrs:{"label":"审核附件","required":""}},[_c('el-upload',{ref:"businessLicense",staticClass:"upload-demo",attrs:{"action":_vm.action,"list-type":"picture-card","on-progress":_vm.progress,"limit":1,"file-list":_vm.fileList,"on-success":_vm.onBusinessLicense,"before-upload":_vm.beforeAvatarUploads},scopedSlots:_vm._u([{key:"file",fn:function(ref){
var file = ref.file;
return (_vm.form.attachmentUrl)?_c('div',{staticStyle:{"text-align":"center"}},[(
                            _vm.form.attachmentUrl.indexOf('.jpg') != -1 ||
                                _vm.form.attachmentUrl.indexOf('.png') != -1 ||
                                _vm.form.attachmentUrl.indexOf('.gif') != -1
                        )?_c('img',{staticClass:"el-upload-list__item-thumbnail",attrs:{"src":_vm.form.attachmentUrl,"alt":""}}):_vm._e(),(
                            _vm.form.attachmentUrl.indexOf('.pdf') != -1 ||
                                _vm.form.attachmentUrl.indexOf('.PDF') != -1
                        )?_c('span',[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(_vm.form.attachmentUrl.indexOf('.doc') != -1)?_c('span',[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e(),_c('span',{staticClass:"el-upload-list__item-actions"},[_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handleDownload(file)}}},[_c('i',{staticClass:"el-icon-download"})]),_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handleRemove(file, _vm.index)}}},[_c('i',{staticClass:"el-icon-delete"})])])]):_vm._e()}}],null,true)},[_c('el-button',{staticStyle:{"background":"#fff","padding":"6px 20px","color":"#2370EB","margin-top":"5px"},attrs:{"size":"small","type":"primary"}},[_vm._v("添加")])],1)],1),_c('el-form-item',{attrs:{"label":"审核备注"}},[_c('el-input',{staticStyle:{"width":"270px"},attrs:{"type":"textarea","placeholder":"审核备注","rows":"6"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1),_c('div',{staticClass:"dialog_footer"},[_c('el-button',{attrs:{"round":"","size":"mini"},on:{"click":_vm.onClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","round":"","size":"mini"},on:{"click":_vm.onSubmit}},[_vm._v("审 核")])],1),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"append-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }