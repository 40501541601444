<template>
    <div class="recharge_box">
        <div class="search-box">
            <div class="search-one">
                <div class="left">
                    <span style="font-weight: 600;">开始时间</span>
                    <el-date-picker
                        size="small"
                        v-model="startTime"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        @change="rechargeListProduct"
                    >
                    </el-date-picker>
                    <span style="font-weight: 600;">结束时间</span>
                    <el-date-picker
                        size="small"
                        v-model="endTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期"
                        :picker-options="pickerOptions"
                        @change="rechargeListProduct"
                    >
                    </el-date-picker>
                </div>
            </div>
            <div class="recharge_mount_box">
                <div class="all_mount_box">
                    <i class="iconfont icon-icon03"></i>
                    <p class="all_mount">
                        总充值金额 <br />
                        <span>{{ allMount }}</span
                        ><i>元</i>
                    </p>
                </div>
                <div class="product_list">
                    <div
                        class="list_item"
                        v-for="(item, index) in allMountList"
                        :key="item.productId"
                    >
                        <i
                            :style="{
                                color:
                                    index % 7 == 0
                                        ? '#2370EB'
                                        : index % 7 == 1
                                        ? '#FA6400'
                                        : index % 7 == 2
                                        ? '#D0021B'
                                        : index % 7 == 3
                                        ? '#FBBE60'
                                        : index % 7 == 4
                                        ? '#5B76F9'
                                        : index % 7 == 5
                                        ? '#51BEE9'
                                        : index % 7 == 6
                                        ? '#FF7E63'
                                        : '',
                            }"
                            class="iconfont icon-youxiang3"
                        ></i>
                        {{ item.productName }}
                        <span>{{ item.rechargeTotalAmount }}</span>
                        <em>元</em>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            placement="bottom"
                        >
                            <div slot="content">
                                <div
                                    v-for="itm in item.launchRechargeChannelBOList"
                                    :key="itm.channelType"
                                    class="channel_mount"
                                >
                                    {{
                                        $tableDataHandle.launchType(
                                            itm.channelType
                                        )
                                    }}
                                    <span>{{ itm.rechargeTotalAmount }}元</span>
                                </div>
                            </div>
                            <p>明细</p>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-title">
            <div>
                <p>
                    <span
                        :class="searchStatus == '' ? 'active' : ''"
                        v-if="btnP.children && btnP.children.length > 1"
                        @click="getAllData('')"
                        >全部 <i>{{ statusBriefMount }}</i>
                    </span>
                    <span
                        :class="searchStatus == item ? 'active' : ''"
                        v-for="item in btnP.children"
                        :key="item"
                        @click="getAllData(item)"
                    >
                        {{
                            item == 'auditing'
                                ? '审批中'
                                : item == 'toPay'
                                ? '待支付'
                                : item == 'rejected'
                                ? '驳回'
                                : item == 'Paid'
                                ? '已支付'
                                : item == 'revoked'
                                ? '撤销'
                                : ''
                        }}

                        <i>{{
                            $tableDataHandle.getRechargeMount(
                                item,
                                statusBriefList
                            )
                        }}</i>
                    </span>
                </p>
                <el-button
                    v-if="btnP.add"
                    type="primary"
                    style="background: #fff;
                border-radius: 2px;
                padding: 0 7px 0 12px;
                height:24px;
                color:#333333;
                border-color: #D9D9D9;
                float:right"
                    @click="addRecharge"
                    ><i
                        style="font-size:12px;color:#999 "
                        class="iconfont icon-shuyi_jinqian-jiage"
                    ></i>
                    申请充值</el-button
                >
            </div>
        </div>
        <div class="table">
            <Table
                ref="table"
                @toAudit="toAudit"
                @toRevoke="toRevoke"
                @toPay="toPay"
                @toDetail="toDetail"
                @totalNum="totalNum"
            ></Table>
        </div>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 充值申请-->
        <el-dialog
            :visible.sync="dialogAddRec"
            width="560px"
            :before-close="handleDialogClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    充值申请
                </div>
            </template>
            <div class="dialog_add_customer_body">
                <el-form label-position="left" label-width="75px" size="small">
                    <el-form-item label="账户名称" required>
                        <el-select
                            v-model="form.launchId"
                            filterable
                            placeholder="请选择"
                            style="width:270px"
                            @change="getBalanceAmount"
                        >
                            <el-option
                                v-for="item in launchDataList"
                                :key="item.id"
                                :label="item.account"
                                :value="item.id"
                            >
                                <span style="float: left">{{
                                    item.account
                                }}</span>
                                <span
                                    style="float: right; color: #8492a6; font-size: 13px"
                                >
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="余额不足1000元"
                                        placement="bottom"
                                    >
                                        <i
                                            v-if="1000 - item.balanceAmount > 0"
                                            class="el-icon-warning"
                                        ></i>
                                    </el-tooltip>
                                </span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="余额">
                        <div>
                            <span
                                v-if="form.launchId"
                                style="background: #F9F9F9;border-radius: 2px;padding:6px 10px;margin-right:10px"
                            >
                                <i
                                    :class="
                                        oneLaunch.refreshBalanceStatus == 1
                                            ? 'el-icon-success'
                                            : oneLaunch.refreshBalanceStatus ==
                                              2
                                            ? 'el-icon-warning'
                                            : ''
                                    "
                                ></i>
                                {{ oneLaunch.balanceAmount + '元' || '- -' }}
                            </span>
                            <span
                                v-if="form.launchId"
                                @click="launchAmountUpdate"
                                style="cursor: pointer;color:#2069DD"
                            >
                                <i class="el-icon-refresh-right"> </i>
                                刷新
                            </span>
                            <span v-else>
                                - -
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item label="充值金额" required>
                        <el-input
                            style="width:245px;margin-right:5px"
                            placeholder="请输入内容"
                            v-model="form.rechargeAmount"
                        ></el-input>
                        元
                    </el-form-item>
                </el-form>
            </div>
            <div class="toast_box">
                <i style="color:#FFA802" class="el-icon-warning"></i>
                充值申请审核通过后，财务则可进行打款并上传凭证
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleDialogClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    @click="submitaddRec"
                    round
                    size="mini"
                    >提交申请</el-button
                >
            </div>
        </el-dialog>
        <!-- 充值审核 -->
        <el-drawer
            :visible.sync="drawerAudit"
            :direction="direction"
            :before-close="handleClose"
            size="477px"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    充值审核
                </div>
            </template>
            <Audit ref="audit" @close="handleClose"></Audit>
        </el-drawer>
        <!-- 打款审核 -->
        <el-drawer
            :visible.sync="drawerPay"
            :direction="direction"
            :before-close="handleClose"
            size="477px"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    充值支付
                </div>
            </template>
            <PayAudit ref="payAudit" @close="handleClose"></PayAudit>
        </el-drawer>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawerDetail"
            :direction="direction"
            :before-close="handleClose"
            size="477px"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    充值历史详情
                </div>
            </template>
            <Detail ref="detail" @close="handleClose"></Detail>
        </el-drawer>
        <!-- 撤销充值申请 -->
        <el-dialog
            :visible.sync="dialogRevoke"
            width="560px"
            :before-close="handleDialogClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    撤销申报
                </div>
            </template>
            <div class="dialog_add_customer_body" style="margin-bottom:150px">
                <p style="font-size:18px">
                    是否确定撤销充值？
                </p>
            </div>
            <div slot="footer" class="bottom-but">
                <el-button
                    @click="handleDialogClose"
                    round
                    style="padding:7px 20px"
                    size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="margin-right:15px;background:#2370EB;padding:7px 20px"
                    round
                    size="mini"
                    @click="revoke"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { launchList, launchAmountUpdate } from '@/api/user/launch/manage.js';
import {
    addRecharge,
    updateRecharge,
    rechargeListProduct,
    statusBrief,
} from '@/api/user/launch/pay.js';
import Table from '../table/table.vue';
import Audit from '../audit/rechargeAudit.vue';
import PayAudit from '../audit/payAudit.vue';
import Detail from '../detail/detail.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            startTime: '',
            endTime: '',
            currentPage: 1,
            pagesize: 20,
            total: 0,
            pickerOptions: {
                disabledDate(time) {
                    let curDate = new Date().toString(); // 当前时间戳转为字符串
                    let curDateYear = new Date().getFullYear(); // 当前时间的年份
                    let oneYearAgoDate = curDate.replace(
                        curDateYear,
                        curDateYear - 1
                    ); // 字符串年份替换为一年前
                    let oneYear = new Date(oneYearAgoDate).getTime(); //一年前字符串转为时间戳
                    return (
                        time.getTime() > Date.now() || time.getTime() < oneYear
                    );
                },
            },
            form: {},
            launchDataList: [],
            allMountList: [],
            statusBriefList: [],
            statusBriefMount: 0,
            allMount: 0,
            oneLaunch: {},
            tableRow: {},
            searchStatus: '',
            btnP: {},
            direction: 'rtl',
            dialogAddRec: false,
            dialogRevoke: false,
            drawerDetail: false,
            drawerAudit: false,
            drawerPay: false,
        };
    },
    components: {
        Table,
        Audit,
        PayAudit,
        Detail,
    },
    created() {},
    mounted() {
        this.getBtn();
    },
    methods: {
        getAllData(just = '') {
            this.searchStatus = just;

            this.getData();
        },
        totalNum(data) {
            this.total = data;
        },
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btn.children = [];
                    btnArr[i].children.forEach((item) => {
                        btn.children.push('' + item.route);
                    });
                    btn.children = Array.from(new Set(btn.children));
                }
            }
            this.btnP = btn;
            this.getData();
            this.rechargeListProduct();
            this.statusBrief();
        },
        getData() {
            this.$refs.table.getData(
                this.searchStatus,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
        },
        // 表头总金额数据统计
        rechargeListProduct() {
            this.allMount = 0;
            let data = {
                param: {
                    status: 4,
                },
            };
            if (this.startTime) {
                data.param.startDate = this.startTime;
            }
            if (this.endTime) {
                data.param.endDate = this.endTime;
            }
            rechargeListProduct(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.allMountList = res.data.list;
                    res.data.list.forEach((item) => {
                        this.allMount += item.rechargeTotalAmount;
                    });
                }
            });
        },
        // 状态统计
        statusBrief() {
            this.statusBriefMount = 0;
            let data = {
                param: {},
            };
            statusBrief(data).then((res) => {
                // console.log(res);

                if (res.code == 200) {
                    this.statusBriefList = res.data.list;
                    res.data.list.forEach((item) => {
                        this.btnP.children.forEach((itm) => {
                            if (item.status == 1 && itm == 'auditing') {
                                this.statusBriefMount += item.statusCount;
                            } else if (item.status == 2 && itm == 'toPay') {
                                this.statusBriefMount += item.statusCount;
                            } else if (item.status == 3 && itm == 'rejected') {
                                this.statusBriefMount += item.statusCount;
                            } else if (item.status == 4 && itm == 'Paid') {
                                this.statusBriefMount += item.statusCount;
                            } else if (item.status == 5 && itm == 'revoked') {
                                this.statusBriefMount += item.statusCount;
                            }
                        });
                    });
                }
            });
        },
        addRecharge() {
            this.dialogAddRec = true;
            this.launchList();
        },
        launchList() {
            let data = {
                param: {},
                pageSize: 0,
                pageNum: 0,
            };
            launchList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.launchDataList = res.data.list;
                }
            });
        },
        getBalanceAmount(val) {
            this.oneLaunch = this.launchDataList.find((item) => item.id == val);
        },
        // 余额刷新
        launchAmountUpdate() {
            let data = {
                param: {
                    channelType: this.oneLaunch.channelType,
                    id: this.oneLaunch.id,
                    userName: this.oneLaunch.userName,
                    password: this.oneLaunch.password,
                    secretKey: this.oneLaunch.secretKey,
                },
            };
            if (this.oneLaunch.channelType == 2) {
                data.param.apiSecret = this.oneLaunch.apiSecret;
            }
            launchAmountUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.launchList();
                    this.oneLaunch.balanceAmount = res.data[0].balanceAmount;
                }
            });
        },
        //     提交充值申请
        submitaddRec() {
            if (!this.form.launchId) {
                return this.$message.error('请选择账户');
            }
            if (!this.form.rechargeAmount) {
                return this.$message.error('请输入充值金额');
            }
            let data = {
                param: {
                    ...this.form,
                },
            };
            addRecharge(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.form = {};
                    this.oneLaunch = {};
                    this.$message.success('操作成功');
                    this.handleDialogClose();
                }
            });
        },
        //     审核
        toAudit(row) {
            this.drawerAudit = true;
            setTimeout(() => {
                this.$refs.audit.getData(row);
            }, 0);
        },
        //     撤销
        toRevoke(row) {
            this.dialogRevoke = true;
            this.tableRow = row;
        },
        revoke() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    status: 5,
                },
            };
            updateRecharge(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.tableRow = {};
                    this.handleDialogClose();
                }
            });
        },
        //     支付
        toPay(row) {
            this.drawerPay = true;
            setTimeout(() => {
                this.$refs.payAudit.getData(row);
            }, 0);
        },
        // 详情
        toDetail(row) {
            this.drawerDetail = true;
            setTimeout(() => {
                this.$refs.detail.getData(row);
            }, 0);
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },

        handleDialogClose() {
            this.dialogAddRec = false;
            this.dialogRevoke = false;
            this.getData();
            this.statusBrief();
        },
        handleClose() {
            this.drawerAudit = false;
            this.drawerPay = false;
            this.drawerDetail = false;
            this.getData();
            this.statusBrief();
            this.rechargeListProduct();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.recharge_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            margin: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                // button {
                //   background: #2370EB;
                //   border-radius: 2px;
                //   border-color: #2370EB;
                //   margin-right: 16px;
                // }

                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }
    .recharge_mount_box {
        border-top: 1px dashed #e5e5e5;
        padding: 15px 18px;
        display: flex;
        align-items: center;
        .icon-icon03 {
            width: 32px;
            height: 32px;
            display: inline-block;
            background-color: #2370eb;
            color: #fff;
            font-size: 18px;
            text-align: center;
            line-height: 32px;
            border-radius: 50%;
        }
        .all_mount_box {
            display: flex;
            align-items: center;
            padding-right: 50px;
            border-right: 1px dashed #e5e5e5;
        }
        .all_mount {
            display: inline-block;
            height: 32px;
            margin-left: 14px;
            font-size: 12px;
            color: #333;
            span {
                color: #2370eb;
                font-size: 18px;
                margin: 0;
            }
            i {
                font-size: 20px;
                display: inline-block;
                transform: scale(0.5);
                font-style: normal;
                vertical-align: sub;
            }
        }
        .product_list {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            padding: 0 20px 0 0;
            margin-top: -10px;
            .list_item {
                height: 40px;
                //    width: 240px;
                border-radius: 1px;
                border: 1px solid #e5e5e5;
                font-size: 13px;
                color: #333;
                display: flex;
                align-items: center;
                i {
                    font-size: 12px;
                    margin-left: 5px;
                    margin-right: 5px;
                }
                span {
                    color: #2370eb;
                    font-size: 16px;
                    margin: 0;
                    width: 100px;
                    flex: 1;
                    text-align: right;
                }
                em {
                    font-style: normal;
                    font-size: 20px;
                    display: block;
                    transform: scale(0.5);
                    margin-top: 5px;
                }
                p {
                    font-size: 10px;
                    border-radius: 2px;
                    border: 1px solid #2370eb;
                    color: #2370eb;
                    padding: 1px 5px;
                    cursor: pointer;
                    background-color: transparent;
                    margin-right: 3px;
                }
            }
            .list_item {
                margin-left: 20px;
                margin-top: 10px;
            }
        }
    }
    .table-title {
        background: #fff;
        padding: 16px;
        div {
            display: flex;
        }
        .active {
            background-color: #2370eb;
            color: #fff;
            i {
                border-left-color: #fff;
            }
        }
        p {
            font-size: 13px;
            font-weight: 400;
            color: #333333;
            display: flex;
            flex: 1;
            line-height: 20px;
            span {
                padding: 1px 10px;
                border-radius: 2px;
                border: 1px solid #e5e5e5;
                margin-right: 16px;
                cursor: pointer;
            }
            i {
                font-style: normal;
                padding-left: 20px;
                border-left: 1px solid #e5e5e5;
                text-align: right;
                margin-left: 10px;
                line-height: 1;
                display: inline-block;
            }
        }
    }
    .table {
        flex: 1;
    }
    .search-two {
        margin-bottom: 10px;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .el-icon-sort-down {
        transform: rotateX(180deg) rotateZ(270deg);
        margin-left: 5px;
        color: #c0c4cc;
        vertical-align: middle;
    }
    .money_input {
        display: inline-block;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        /deep/ .el-input__inner {
            border: none;
            padding: 0 15px;
        }
    }
}
.dialog_add_customer {
    /deep/.el-dialog__footer {
        padding: 0;
        text-align: right;
        height: 50px;
        line-height: 50px;
        border-top: 1px solid #eeeeee;
        padding-right: 18px;
    }

    .dialog_add_customer_body {
        width: 65%;
        margin: 20px auto 50px;
    }
    .toast_box {
        font-size: 13px;
        background: rgba(35, 112, 235, 0.1);
        text-align: center;
        line-height: 1.5;
    }
}
.radio_group {
    display: inline-block;
    padding: 6px 0;
    em {
        padding: 7px 15px;
        font-size: 12px;
        font-style: normal;
        white-space: nowrap;
        background: #fff;
        border: 1px solid #dcdfe6;
        font-weight: 500;
        cursor: pointer;
        margin-left: -1px;
    }
    em:first-child {
        border-radius: 4px 0 0 4px;
        margin-left: 0;
    }
    .active {
        background: #fff !important;
        color: #2162cb !important;
        border-color: #2162cb !important;
    }
    .active + em {
        border-left: 1px solid #2162cb;
    }
}
.hoverBtn {
    padding: 8px 5px;
    margin-left: 15px;
    background: transparent;
    color: #2370eb;
    border-color: #2370eb;
}
.hoverBtn:hover {
    background-color: #2370eb;
    color: #fff;
}
.el-icon-success {
    color: #2370eb;
}
.el-icon-warning {
    color: #ffa802;
}
.el-icon-refresh-right {
    color: #216ce5;
}
.channel_mount {
    width: 150px;
    span {
        float: right;
    }
}
.channel_mount + .channel_mount {
    margin-top: 10px;
}
</style>
