<template>
    <div class="audit_box">
        <BaseInfo ref="baseInfo"></BaseInfo>
        <el-form label-position="left" label-width="80px" size="small">
            <el-form-item label="审核结果">
                <el-radio-group v-model="form.status">
                    <el-radio :label="1">审核通过</el-radio>
                    <el-radio :label="0">审核驳回</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="审核备注">
                <el-input
                    v-model="form.remark"
                    type="textarea"
                    placeholder="审核备注"
                    rows="6"
                    style="width:270px"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="dialog_footer">
            <el-button round size="mini" @click="onClose">取 消</el-button>
            <el-button type="primary" round size="mini" @click="onSubmit"
                >审 核</el-button
            >
        </div>
    </div>
</template>

<script>
import { getOneRecharge, rechargeAudit } from '@/api/user/launch/pay.js';
import BaseInfo from '../detail/components/baseInfo.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableRow: {},
            form: {
                status: 1,
                remark: '',
            },
        };
    },
    components: {
        BaseInfo,
    },
    created() {},
    methods: {
        getData(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            getOneRecharge(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.tableRow = res.data;
                    setTimeout(() => {
                        this.$refs.baseInfo.getData(res.data);
                    }, 0);
                }
            });
        },
        onSubmit() {
            let data = {
                param: {
                    rechargeId: this.tableRow.id,
                    status: this.form.status,
                    remark: this.form.remark,
                    type: 1,
                },
            };
            rechargeAudit(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.form = {
                        status: 1,
                        remark: '',
                    };
                    this.tableRow = {};
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.audit_box {
    width: 100%;
    height: 100%;
    padding: 24px 35px;
    box-sizing: border-box;
    position: relative;
    .dialog_footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 45px;
        line-height: 45px;
        text-align: right;
        padding-right: 10px;
        box-sizing: border-box;
        border-top: 1px solid #e5e5e5;
    }
}
.el-form-item {
    margin-bottom: 10px;
}
</style>
